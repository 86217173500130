document.addEventListener("DOMContentLoaded", () => {
  const inputs = document.querySelectorAll("input");

  inputs.forEach(el => {
    el.addEventListener("input", () => {
      let parent = el.parentElement;

      if (el.classList.contains("is-invalid")) {
        el.classList.remove("is-invalid");
      } else {
        return;
      }

      while (parent) {
        if (parent.classList.contains("form-group-invalid")) {
          let sibling = parent.nextElementSibling;

          while (
            sibling &&
            // Allow strength meter because that can be between error output and
            // the input
            (sibling.classList.contains("password-strength-meter") ||
              sibling.classList.contains("invalid-feedback"))
          ) {
            // We need to cache the el before we remove it, otherwise we can't
            // get its next sibling
            const thisEl = sibling;
            sibling = sibling.nextElementSibling;

            // If the element is the strength meter, we don't want to remove it
            if (!thisEl.classList.contains("password-strength-meter")) {
              thisEl.parentElement.removeChild(thisEl);
            }
          }

          parent.childNodes.forEach(child => {
            if (
              child.classList &&
              child.classList.contains("invalid-feedback")
            ) {
              parent.removeChild(child);
            }
          });

          parent.classList.remove("form-group-invalid");
        }

        parent = parent.parentElement;
      }
    });
  });
});
